<template>
  <div class="container">
    <div class="header_box flexSb">
      <div class="flexSt list_box">
        <div class="flexSt list">
          <div class="title">学校</div>
          <el-select
            @change="handleSearch"
            v-model="form.school"
            placeholder="请选择学校"
            filterable
          >
            <el-option
              v-for="(item, index) in schoollist"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="flexSt list list2">
          <div class="title">年级/班级</div>
          <el-cascader
            placeholder="年级/班级"
            :options="options"
            v-model="form.grade"
            :props="{ children: 'class' }"
            @change="handleChange"
            filterable
            clearable
          ></el-cascader>
        </div>
        <div class="flexSt list">
          <div class="title">老师名称</div>
          <el-input
            clearable
            v-model="form.name"
            placeholder="请输入老师名称"
          ></el-input>
        </div>
        <div class="flexSt list">
          <div class="title">手机号码</div>
          <el-input
            clearable
            v-model="form.mobile"
            placeholder="请输入手机号码"
          ></el-input>
        </div>
        <div class="flexSt list">
          <div class="title">性别</div>
          <el-select
            @change="handleSearch"
            v-model="form.gender"
            placeholder="请选择性别"
          >
            <el-option
              v-for="(item, index) in genderlist"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="flexSt list">
          <div class="title">专业特长</div>
          <el-select
            @change="handleSearch"
            v-model="form.region"
            placeholder="请选择专业特长"
          >
            <!-- <el-option label="男" value="shanghai"></el-option> -->
          </el-select>
        </div>
        <div class="flexSt list">
          <div class="title">状态</div>
          <el-select
            @change="handleSearch"
            v-model="form.status"
            placeholder="请选择状态"
          >
            <el-option
              v-for="(item, index) in statuslist"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="flexSb list">
        <el-button @click="handleSearch" type="primary" icon="el-icon-search"
          >搜索</el-button
        >
        <el-button @click="handleclearSearch" icon="el-icon-refresh"
          >重置</el-button
        >
      </div>
    </div>
    <div class="add flexSe">
      <!-- <div class="flexSt">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleEdit('/editTeacher?type=add')"
          >添加教师</el-button
        ><el-button icon="el-icon-delete" @click="handleShowDel"
          >删除</el-button
        >
      </div> -->
      <div>
        <div class="flexSb">
          <el-button icon="el-icon-download">导入</el-button>
          <el-button icon="el-icon-upload2">导出</el-button>
        </div>
      </div>
    </div>
    <div class="table_box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="tableHeaderColor"
        :header-cell-class-name="cellClass"
        :cell-class-name="returnName"
      >
        <el-table-column type="selection" width="25"> </el-table-column>
        <el-table-column
          :index="indexMethod"
          type="index"
          label="序号"
          width="60"
          align="center"
        >
        </el-table-column>

        <el-table-column label="用户信息" width="180">
          <template slot-scope="scope">
            <div class="flexSc">
              <a href="">
                <img
                  v-if="scope.row.gender == 1"
                  class="img"
                  src="@/assets/img/1634.png"
                  alt=""
                />
                <img v-else class="img" src="@/assets/img/1633.png" alt="" />
              </a>
              <div>
                <div class="flexSt">
                  <a href="">
                    <div class="name">{{ scope.row.name }}</div>
                  </a>
                  <img
                    v-if="scope.row.gender == 1"
                    class="img_gender"
                    src="@/assets/img/206.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="img_gender"
                    src="@/assets/img/207.png"
                    alt=""
                  />
                </div>
                <div>{{ scope.row.mobile }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="180" label="学校" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.education_name ? scope.row.education_name : "- -" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="90" label="学历" sortable align="center">
          <template slot-scope="scope">
            <div style="padding-right: 16px">
              {{ scope.row.education_name ? scope.row.education_name : "- -" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="80" label="教龄" sortable align="center">
          <template slot-scope="scope">
            <div style="padding-right: 16px">
              {{ scope.row.age ? scope.row.age : "- -" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="专业特长" min-width="120" align="center">
          <template slot-scope="scope">
            <div class="flexSc tag_box">
              <div v-if="scope.row.speciality.length > 0">
                <div
                  v-for="(item, index) in scope.row.speciality_name"
                  :key="item"
                >
                  <el-tag v-if="index <= 4" class="tag" type="info">{{
                    item
                  }}</el-tag>
                  <el-tag v-else class="tag" type="info">...</el-tag>
                </div>
              </div>
              <!-- {{ scope.row.data1 ? scope.row.data1 : "- -" }} -->
              <div v-else>- -</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="授课班级" align="center" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.class_name ? scope.row.class_name : "- -" }}
          </template></el-table-column
        >
        <el-table-column
          label="体育课出勤率"
          width="140"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            - -
            <!-- {{ scope.row.data1 ? scope.row.data1 : "- -" }} -->
          </template>
          <!-- --> </el-table-column
        ><el-table-column
          label="大课间出勤率"
          width="140"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            - -
            <!-- {{ scope.row.data1 ? scope.row.data1 : "- -" }} -->
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="60" align="center">
          <template v-slot:default="{ row }">
            <div v-if="row.status == 0" style="color: #537af2">在职</div>
            <div v-else style="color: #ff507c">离职</div>
          </template>
        </el-table-column>
        <el-table-column width="120" fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <div class="flexSb">
              <!-- <el-button
                type="text"
                @click="
                  handleEdit(
                    '/editTeacher?id=' +
                      scope.row.id +
                      '&type=edit' +
                      '&info=' +
                      JSON.stringify(scope.row)
                  )
                "
                >编辑</el-button
              > -->
              <el-button @click="handleDetail(scope.row)" type="text"
                >综合评价</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="flexSe elpla">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
        >
        </el-pagination>
      </div>
    </div>
    <delDialog ref="delDialog" @handleDel="handleDelTeacher"></delDialog>
  </div>
</template>
<script>
import delDialog from "@/components/delDialog/index";
import { getUser } from "@/utils/auth";
// import { getTeacher, gradeClassList, delTeacher } from "@/api/evaluation";
export default {
  components: { delDialog },
  data() {
    return {
      schoollist: [
        {
          label: "全区",
          value: -1,
        },
      ],
      options: [],
      form: {
        name: "",
        grade: [],
        mobile: "",
        gender: -1,
        status: -1,
        class_id: "",
        school: -1,
      },
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 3,
      },
      tableData: [],
      searchValue: "",
      genderlist: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 0,
        },
      ],
      statuslist: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "在职",
          value: 0,
        },
        {
          label: "离职",
          value: 1,
        },
      ],
      value: [],
      selTeacherlist: [],
    };
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$forceUpdate();
    },
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      this.$forceUpdate();
      this.userInfo = getUser();
      // this.getTeacher();
      // this.handleGetGradeClassList();
    });
  },
  methods: {
    handleEdit(url) {
      this.$router.push(url);
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    handleShowDel() {
      console.log(this.$refs.delDialog);
      if (this.selTeacherlist.length > 0) {
        this.$refs.delDialog.handleShow();
      } else {
        this.$message.warning("请先选择数据");
      }
    },
    async handleDelTeacher() {
      console.log(1);
      let ids = [];
      this.selTeacherlist.forEach((item) => {
        ids.push(item.id);
      });
      let data = {
        id: ids.join("_"),
      };

      await delTeacher(data).then((res) => {
        if (this.selTeacherlist.length == this.tableData.length) {
          this.pageInfo.page = this.pageInfo.page - 1;
        }
        this.$message.success("操作成功");
        this.getTeacher();
      });
    },
    handleChange(val) {
      console.log(val);
      if (val.length > 0) {
        this.form.class_id = val[1];
      } else {
        this.form.class_id = "";
      }
      this.handleSearch();
    },
    handleSearch() {
      this.pageInfo.page = 1;
      this.getTeacher();
    },
    handleclearSearch() {
      this.form = {
        name: "",
        grade: [],
        mobile: "",
        class_id: "",
        gender: -1,
        status: -1,
      };
      this.pageInfo.page = 1;
      this.getTeacher();
    },

    // 获取教师列表
    async getTeacher() {
      let data = Object.assign({}, this.form);
      data.per_page = this.pageInfo.pageSize;
      data.page = this.pageInfo.page;
      await getTeacher(data).then((res) => {
        this.tableData = res.data.data;
        this.pageInfo.total = res.data.total;
      });
    },

    // 年级班级
    async handleGetGradeClassList() {
      await gradeClassList().then((res) => {
        this.options = res.data;
      });
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleDetail(val) {
      this.$router.push("/teacherevaluationdetail?id=" + val.id);
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.getTeacher();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.getTeacher();
    },
    handleSelectionChange(val) {
      console.log(val, "=13584548542");
      this.selTeacherlist = val;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  /deep/.firstCell {
    .cell {
      overflow: unset !important;
    }
  }
  background-color: #fff;
  min-height: 100%;
  overflow-y: auto;
  padding: 36px 25px;
  box-sizing: border-box;
  .elpla {
    margin-top: 30px;
  }
  .list_box {
    flex-wrap: wrap;
  }
  .header_box {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-bottom: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .list {
      margin-bottom: 10px;
    }
    .list2 {
      /deep/.el-cascader {
        width: 147px !important;
        margin-right: 10px;
      }
    }
    /deep/.title {
      color: rgb(78, 89, 105);
      font-size: 14px;
      margin-right: 8px;
      // min-width: 60px;
    }
    .sex {
      /deep/.el-form-item__label {
        width: 47px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 60px !important;
      }
      /deep/.el-select {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        width: 127px !important;
      }
    }
    .sex2 {
      /deep/.el-form-item__label {
        width: 80px !important;
      }
      /deep/.el-form-item__content {
        width: 167px !important;
      }
      /deep/.el-select {
        width: 147px !important;
        height: 32px !important;
      }
    }
    .sex3 {
      /deep/.el-select,
      /deep/.el-input__inner,
      /deep/.el-input {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        // margin-left: 40px !important;
      }
    }
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
    /deep/.el-input,
    /deep/.el-form-item__content,
    /deep/.el-input__inner,
    /deep/.el-form-item__labe {
      height: 32px !important;
      width: 147px;
    }
    /deep/.el-input {
      margin-right: 15px;
    }

    /deep/.el-input__icon {
      line-height: 32px !important;
    }

    /deep/.el-form-item__content {
      width: 171px !important;
    }
  }

  .add {
    margin: 17px 0;
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
  }
  .table_box {
    a {
      cursor: pointer;
    }
    .img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .img_gender {
      width: 20px;
      height: 20px;
    }
    .name {
      font-size: 14px;
      color: #1890ff;
      margin-right: 6px;
    }
    .tag_box {
      flex-wrap: wrap;
      .tag {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
    /deep/.disableSelection {
      // background-color: pink;
      /deep/.cell {
        // background-color: red;
      }
    }
  }
  // ::-webkit-scrollbar {
  //   width: 10px !important;
  //   height: 10px !important;
  //   background-color: #ccc;
  // }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    // background-color: #ccc !important;
  }
  ::-webkit-scrollbar-track {
    // background-color: #bbbbbb;
  }
  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  // /deep/.cell {
  //   border: 1px solid;
  //   box-sizing: border-box;
  // }
}
</style>
